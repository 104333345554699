import React, { useState } from 'react';
import styles from './Students.module.css';
import Input from '../shared/ui/Input/Input';
import Modal from '../shared/ui/Modal/Modal';
import SearchInput from '../shared/ui/Input/SearchInput';
import {auth, db, apiURL} from '../../firebase';
import TopBar from '../Resume/TopBar';
import Resume from '../Resume/Resume';
import axios from 'axios';
import { CreateToast } from '../../store/actions/alert';
import { connect } from 'react-redux';


const modalStyle = {
    maxWidth: 1100,
    margin:'150px 0',
    top:0,
    borderRadius:14,
    transform:"translateX(-50%)",
    position:'relative',
    background:'transparent'
}

const StudentContainer = (props)=> {
    const [student, setStudent] = useState(null);
    const [showModal, setShowModal] = useState(false);


    const SearchHandler = async (email)=>{

        let baseUrl = apiURL;
        let url = baseUrl + '/resume/' + email
        let token = await auth.currentUser.getIdToken()

        try {
            let resume = (await axios.get( url, {
                    headers: {
                        token: token,
                    }})).data;
            
            
            resume.email = email
        
                setStudent(resume);
                setShowModal(true);
            
        } catch (error) {
            console.log(error.response, "error aagya :'(")
            props.createToast({message:error.response.data})
            console.log(error)
        }

        console.log(email)
        try{

            let userRes = await auth.get
            if(userRes.size <= 0) 
                return console.log("user not found");

            let res = await db.collection('resume').where('email', '==', email).get();
            if(res.size > 0)
            {   
                let stud = res.docs[0].data();
                stud.uid = res.docs[0].id;
                setStudent(stud);
                setShowModal(true);
            }
        }
        catch(e){
            console.log(e);
        }
    }

    const updateVerification = async (status)=>{
        let token = await auth.currentUser.getIdToken();

        let url = apiURL + '/resume/' + student.email + '/verify'
        try {
            await axios.post(url, {"verificationStatus":status} , {
                headers: {
                    token: token
                }
            });
            setStudent({...student, verified: status});
        }
        catch(error){
                console.log(error)
                props.createToast({message:'Something went wrong'})
        }
    }
  

    return(
    <div className={styles.Content} >
        <h1>Students</h1>
        <div>
            <SearchInput handler={SearchHandler}/>
        </div>
            <Modal show={showModal} style={modalStyle} closeHandler={()=> setShowModal(false)}>
                <TopBar close={()=> setShowModal(false)}/>
                <Resume 
                    student={student} 
                    updateVerification={updateVerification}
                    // updateStatus={(status)=>context.updateStatus(student.id, status)} 
                    close={()=> setShowModal(false)}
                    />
            </Modal>
    </div>
)}

const mapDispatchToProps = (dispatch)=>({
    createToast: (toast)=>dispatch(CreateToast(toast))
})


export default connect(null, mapDispatchToProps)(StudentContainer);