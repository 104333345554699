import React from 'react';
import {ErrorMessage} from 'formik';


function Error(props) {
    return (<ErrorMessage name={props.name}>
            {(msg)=>(<span className='Error'>{msg}</span>)}
        </ErrorMessage>);
}

export default Error
