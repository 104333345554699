import React, { useState } from 'react';
import styles from './CompanyContent.module.css';
import Button from '../shared/ui/Button/Button';

export default (props)=>{
    const [loading, setLoading] = useState(false);
    let company = props.company;
    let socialMedia = [];

    for(let i in company.social_media){
        if(company.social_media[i])
        socialMedia.push(company.social_media[i])
    }

    let verifyButtonText = company.verified? "Unverify": "Verify";

    const verifyHandler = async ()=>{
        setLoading(true)
        await props.changeVerification(!company.verified);
        setLoading(false);
    }

    return <div className={styles.Container}>
        <div className={styles.Header}>
            <div className={styles.LogoContainer}>
                <img className={styles.Logo} src={company.logo}/>
            </div>
            <div className={styles.HeaderContent}>
                <div className={styles.Name}>{company.name}</div>
                <div>{company.email}</div>
                <div>{company.phone}</div>
                <a href={company.website}>{company.website}</a>
                <div>{company.industry_type}</div>
            </div>
            <div>
                <Button loading={loading} primary={!company.verified} clicked={verifyHandler}>{verifyButtonText}</Button>
            </div>
        </div>
        <div className={styles.Content}>
            <div className={styles.Field}>
                <div className={styles.Sub}>About</div>
                <div className={styles.Title}>{company.about}</div>
            </div>
            <div className={styles.Field}>
                <div className={styles.Sub}>Address</div>
                <div className={styles.Title}>{company.company_address}</div>
            </div>
            <div className={styles.Field}>
                <div className={styles.Sub}>Social Media</div>
                <div className={styles.Title}>{ socialMedia.length ? socialMedia.map( (link,i)=> <a key={i} href={link}>{link}</a>): "None" }</div>
            </div>
        </div>
    </div>
}