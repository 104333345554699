import React, {useEffect, useState} from 'react'
import '../../Table.css';
import styles from './LogsTable.module.css';
import RefreshIcon from '../../assets/icons/refresh.svg'
import {db} from '../../firebase';


function RatingTable() {
    const limit = 2;
    const [ logs, setLogs] = useState([]);
    const [ loading, setLoading] = useState(false);
    const [ showLoadmore, setShowLoadMore] = useState(false);

    useEffect(() => {
        Load();
    }, []);

    const Load = ()=>{
        setLoading(true);
        db.collection('rating_data').orderBy('uploaded_at', 'desc').limit(limit).get().then(snap=>{
            setLogs(snap.docs.map(doc=>doc.data()));
            if(snap.size == limit) setShowLoadMore(true);
            setLoading(false)
        })
    }

    const LoadMore = ()=>{
        setLoading(true);
        setShowLoadMore(false);
        db.collection('rating_data').orderBy('uploaded_at', 'desc').startAfter(logs[logs.length-1].uploaded_at).limit(limit).get().then(snap=>{
            console.log(snap.docs.map(doc=>doc.data()));
            setLogs([...logs, ...snap.docs.map(doc=>doc.data())]);
            if(snap.size == limit) setShowLoadMore(true);
            setLoading(false)
        })
    }

    return (
        <div className={styles.Logs}>
            <h2>Logs <button onClick={Load} className={[styles.ReloadBtn, loading? styles.Reloading:null].join(" ")}><img src={RefreshIcon}/></button></h2>
            <div className="Table">
                <div className={`TableHeaderRow Row ${styles.LogTableRow}`}>
                    <span>Title</span>
                    <span>Skills</span>
                    <span>S/R/T</span>
                    <span>Uploaded By</span>
                    <span className="CenterCell Cell">Status</span>
                </div>
                <div className="TableContent">
                    {logs.map((log,i)=> <LogRow key={i} log={log}/>)}
                </div>
                { showLoadmore && <div className="Loadmore" onClick={LoadMore} >
                    Load More
                </div>}
            </div>
        </div>
    )
}

export default RatingTable


const LogRow = ({log, ...props})=>{
    let status = log.status;
    let createdAt =  log.uploaded_at.toDate();

    return(
    <>
    <div className={`Row ${styles.LogTableRow}`}>
        <a className='NameCell Cell' href={`https://firebasestorage.googleapis.com/v0/b/oneios.appspot.com/o/${encodeURIComponent(log.file_path)}?alt=media`} target="_blank">
            <span className="Title">
                {log.title}
            </span>
            <span className="Subtitle">
                {log.file_name}
            </span>
        </a>
        <div className={`Cell ${styles.Skills}`}>
            {log.skill.map(s=><span key={s}>{s}</span>)}
        </div>
        <div className={`Cell`}>
            {log.students?.selected || '-'}/{log.students?.rejected || '-'}/{log.students?.total|| '-'}
        </div>
        <div className={`DeadlineCell Cell`}> 
            <span className={styles.Title}> { log.uploaded_by_email} </span>
            <span className={styles.Subtitle}>{ createdAt && createdAt.toDateString()}</span>
        </div>
        <div className={`StatusCell Cell CenterCell`}>
            <span className={`Tag ${status}`}>{status}</span>
        </div>
        { log.details &&
        <p className={styles.Details}>
            {log.details}
        </p>}
    </div>

    </>
    
    )
}