import React, { Component, useState } from 'react';
import styles from './HomeInfo.module.css';
import ButtonImage from './images/buttonimage.svg'
import Button from '../shared/ui/Button/Button';
import ThirdBox from './ThirdBox.js'
import moment from 'moment';
import { Link } from 'react-router-dom';

import { Fragment } from 'react';
import { db } from '../../firebase';

class homeInfo extends Component{
    formatDate = (timestamp)=>{
        var t = new Date(1970, 0, 1);
        let dateTime = t.setSeconds(timestamp.seconds);
        return moment(dateTime).format('DD MMM\'YY');
    }

    state={
        showConfirmation:false,
    }
    render (){
    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <div className = {styles.headerInner}>
                    <div className = {styles.titleBox}>
                        <h1 className = {styles.title}>{this.props.job?.title}</h1>
                        <div className = {styles.desc}>{this.props.job?.type} |  {this.props.job?.ctc}  |  {this.formatDate(this.props.job?.deadline)}</div>
                    </div>
                    <div className = {styles.buttonsContainer}>
                        {/* <button onClick={this.props.deleteJob} className = {styles.deletejobBox}>
                        Delete Job
                        </button> */}
                        <div className = {styles.appliedBox}>
                                <span onClick={this.props.openChangeDeadline} style={{display:'inline-block', marginRight: '11px', color:'#0075FF'}}>Change Deadline</span><img style = {{display:'inline-block'}} src = {ButtonImage}></img>

                        </div>
                    </div>     
                </div>
            </div>
            <div className = {styles.secondaryBox}>
                <div className = {styles.categoryBox}>
                    <div className = {styles.catBox}>
                        <div className = {styles.catTitle}>Job Category:</div>
                        <div className = {styles.cat}>{this.props.job.category}</div>
                    </div>
                    <div className = {styles.catBox}>
                        <div className = {styles.catTitle}>Job Location:</div>
                        <div className = {styles.cat}>{this.props.job.job_loc}</div>
                    </div>
                    <div className = {styles.catBox}>
                        <div className = {styles.catTitle}>Interview Location:</div>
                        <div className = {styles.cat}>{this.props.job.drive_loc}</div>
                    </div>
                    {/* <div className = {styles.catBox}>
                        <div className = {styles.catTitle}>Who Can Apply:</div>
                        <div className = {styles.cat}>{this.props.job.placed?"All":"Unplaced"}</div>
                    </div> */}
                </div>
            </div>
            <ThirdBox job={this.props.job}/>
            <div style = {{paddingLeft:"18px", paddingTop:"18px"}}>
                <div className = {styles.catTitle}>
                    Schedule:
                </div>
                <div className = {styles.descpara}>
                {/* 11:00 AM - Apptitude    |    12:00 PM - GD    |    5:00 PM - PI */}
                {this.props.job.schedule.split("\n").map((line , i)=> (<Fragment key={i}>{line}<br/></Fragment>))}
                </div>
            </div>
            {this.props.job.recipient? <div className={styles.Qualifications}>
                <div className = {styles.catTitle}>
                        Recipients:
                </div>
                <div>
                    {Object.keys(this.props.job.recipient).sort().map((rec, i)=> <Recipient key={i} changeStatus={(status)=> this.props.changeStatus(rec, status)} name={rec} jobId={this.props.job.uid} status={this.props.job.recipient[rec]} />)}
                </div>
            </div>:null}


            {this.props.job.edu?
            <div className={styles.Qualifications}>
                <div className = {styles.catTitle}>
                    Qualifications:
                </div>
                {formatQual(this.props.job.edu).map((qual,i)=> <Qualification {...qual} key={i} />)}
                {/* <Button clicked={this.props.sendInvites} style={{fontSize:14, padding:'9px 19px', width:'unset'}}>
                    Send Invites To More Colleges
                </Button> */}
            </div>:null}
        </div>
    )   
  }
}

const Recipient = (props)=>{

    const [loading, setLoading] = useState(false);


    let buttonText = "Reject";
    if(props.status != "live")
        buttonText="Accept";
    
    const ButtonHandler = ()=>{
        let status = "rejected"
        if(props.status != "live")
            status="live";
        
        console.log(props.jobId, status)
        setLoading(true);
        db.collection('jobs').doc(props.jobId).update({[`recipient.${props.name}`] : status })
        .then(()=> {
            setLoading(false);
            props.changeStatus(status);
            console.log('sucess');
        }).catch((e)=>{
            console.log('not sucess', e);
            setLoading(false)
        });
    }
   
    

    return (
    <div className={styles.Recipients}>
        <span>
        {props.name} 
        </span>
        <span className={[styles.Status, styles[props.status]].join(" ")}> {props.status}</span>
        <Button clicked={ButtonHandler} disabled={loading} loading={loading} style={{width: 'unset', fontSize: '12px', padding: '5px 10px'}}>
            {buttonText}
        </Button>  
    </div>)
}

const Qualification= ({college, degree, course, branch, year})=>{

    let Branches = branch.reduce((str, curBranch)=> str + curBranch + ', ', '');
    let Years = year.reduce((str, curYear)=> str + curYear + ', ', '');
    Years=Years.slice(0, Years.length -2)
    Branches=Branches.slice(0, Branches.length -2)


    return(
        <div className={styles.Qual}>
            <div className={styles.College}>
                {college}
            </div>
            <div className={styles.CourseList}>
                {Years} Year | {degree} | {course} | {Branches}
            </div>
        </div>)
}

const formatQual = (edu)=>{
    let formatedEdu = [];
    if(!edu) return null;
   Object.keys(edu).forEach(qual=>{
        let [college,degree,course,branch,year] = qual.split('#');
        if(!year)
          return
        let index = formatedEdu.findIndex(fQual=> fQual.course == course && fQual.degree == degree && fQual.college == college);
        if(index>-1)
        {
            if(formatedEdu[index].branch.includes(branch)==false)
                formatedEdu[index].branch.push(branch);
            if(formatedEdu[index].year.includes(year)==false)
                formatedEdu[index].year.push(year);
        }
        else
            formatedEdu.push({college, degree, course, branch:[branch], year:[year]})
    });
    return formatedEdu
  }
  

export default homeInfo;
