import React, { useRef } from 'react';
import styles from './SearchInput.module.css';

const SearchInput = (props)=>{

    const input = useRef(null);
    let timer = null;

    const search = ()=>{
        clearTimeout(timer);
        timer = setTimeout(()=>{
            props.handler(input.current.value);
        }, 400);
    }   

    return (
        <div className={styles.InputContainer}>
            <input ref={input} onInput={search} type="text" placeholder="Search..." className={styles.Input}/>
            <button onClick={search} className={styles.Button}>Search</button>
        </div>
    )
}

export default SearchInput;