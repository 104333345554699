import React from 'react';
import styles from './Rating.module.css';
import RatingForm from './RatingForm';
import LogsTable from './LogsTable'

const RatingContainer = (props)=>{
    return(<div className={styles.Content}>
            <h1>Rating</h1>
            <RatingForm/>
            <LogsTable/>
        </div>)
}

export default RatingContainer;