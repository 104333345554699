import { render } from '@testing-library/react';
import { CreateToast } from '../../store/actions/alert';
import { connect } from 'react-redux';
import axios from 'axios';
import React , { createContext, Component, createRef, useState, useEffect, useLayoutEffect} from 'react';
import { auth, db, Mode } from '../../firebase'
import userPlaceholder from '../../assets/images/user_placeholder.jpg';
import { storage } from '../../firebase'
import { withRouter } from 'react-router-dom'
export const StudentsContext = createContext();


class StudentsProviderComponent extends Component{
    state = {
        jobsdata: [],
        countdata: [],
        applicants: [],
        studentLoading: false,
        filters: {  degree: 'All',
                    course: 'All',
                    field: 'All',
                    flag: 'All',
                    collegeid: 'All',
                    skillValue: [],
                    selectedCollegeData: undefined,     
        },
        options: {  degreeOptions: ['All'],
                    courseOptions: ['All'],
                    branchOptions: ['All'],
                    collegeOptions: ['All'],
        },
        searchValue: '',
        showHired:false,
    }

    async componentDidMount (){
        console.log(this.props)
        if(this.props.hired){
            this.setState({showHired:true})
        }
        this.getCollegeList();
        // this.fetchStudents(this.state.filters, false, this.props.hired)
    }

    endOfPageHandler = ()=>{
        if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
            console.log("end of page")
            if(!this.state.studentLoading && !this.state.studentLoading && this.state.hasMore){
                console.log(this.state.applicants.length!=this.props.count.count);
                this.setState({studentLoading:true})
                this.fetchStudents(this.state.filters, true)
            }
        }
    }

    componentWillMount(){
        window.addEventListener('scroll', this.endOfPageHandler);
    }

    componentWillUnmount(){
        window.removeEventListener('scroll', this.endOfPageHandler);
    }

    setSearch = (search)=>{
        this.fetchStudents(search);
    }

    fetchStudents = async(search)=>{
        console.log(search);
        let baseUrl = 'https://api.ensvee.com/'
        if(Mode=="TEST") 
          baseUrl = 'http://localhost:8000/'
        let url = baseUrl + 'resume/' + search
        let user = this.props.user
        let token = await auth.currentUser.getIdToken()
        console.log(user, "user")
        console.log(token, "token?")
        try {
            let resume = (await axios.get(url, {
                headers: {
                  token: token,
                }})).data
            
            {
                console.log(resume)
                resume.profilePic = userPlaceholder
                resume.email = search
                let applicant = []
                applicant.push(resume)
                let redirectionurl = '/student/' + search
                this.setState({applicants:applicant})
                this.props.history.replace(redirectionurl)
                console.log(applicant)
                // this.getImages(applicant)
            }
        } catch (error) {
            console.log(error.response, "error aagya :'(")
            this.props.createToast({message:error.response.data})
            console.log(error)
        }
    }

    // fetchStudents = async (filters, moreStudents = false, showHired= false)=>{
    //         let applicants = []
    //         let query =  db.collection('jobs').doc(this.props.jobId).collection('applicants').where('status', '==', 'Applied').limit(20);
    //         console.log(this.props.hired, "showHired")
    //         if(this.props.hired){
    //             query =  db.collection('jobs').doc(this.props.jobId).collection('applicants').where('status', '==', 'Hired').limit(20);
    //         }
    //         for (let filterKey in filters){
    //             if(filters[filterKey]!='All' && filters[filterKey]!='' && filterKey!='selectedCollegeData'){
    //                 if(filterKey=="course"||filterKey=="field"){
    //                     query = query.where(`edu.${filters.degree}.${filterKey}`, '==', filters[filterKey])
    //                 }
    //                 else if(filterKey=="skillValue"){
    //                     query = query.where('skillkey', 'array-contains-any', filters[filterKey])
    //                     console.log(filters[filterKey])
    //                 }
    //                 else{
    //                     query = query.where(filterKey, '==', filters[filterKey])
    //                 }
    //             }
    //         }
    //         if(moreStudents){
    //             let studs = this.state.applicants.length
    //             let lastStudent = this.state.applicants[studs - 1];
    //             console.log(lastStudent.id);
    //             let lastStudentDoc = await db.collection('jobs').doc(this.props.jobId).collection('applicants').doc(lastStudent.id).get();
    //             query = query.startAfter(lastStudentDoc)
    //         }
    //         let studentDocslist = await query.get();
    //         console.log(studentDocslist, "studoclist");
    //         studentDocslist.forEach(applicantsDoc=>{
    //             let applicant = applicantsDoc.data();
    //             console.log("Skills", applicant.hskills, applicant.sskills)
    //             // let hskills = new Map([...applicant.hskills].sort())
    //             // let hskills = new Map([...applicant.hskills.entries()].sort());
    //             // let hskills = Object.keys(applicant.hskills).sort(function(a,b) { return applicant.hskills[a] - applicant.hskills[b]; });
    //             // console.log("sorted skills", hskills)
    //             applicant.hskills = this.skillSorter(applicant.hskills)
    //             applicant.sskills = this.skillSorter(applicant.sskills)
    //             applicant.id= applicantsDoc.id;
    //             applicant.profilePic = userPlaceholder
    //             applicants.push(applicant);
    //             console.log(applicant);
    //         });
    //         if(moreStudents){
    //             this.setState({applicants:[...this.state.applicants, ...applicants], studentLoading:false, hasMore: (applicants.length==20)},()=>this.getImages(applicants))
    //         }
    //         else{
    //             this.setState({applicants: applicants, studentLoading: false, hasMore: (applicants.length==20)},()=>this.getImages(applicants));
    //         }
    //         // this.setState({studentLoading: false});
    //         console.log(applicants, "applicants renewed")
    // }

    skillSorter = (propskills) =>{
        let skills = Object.keys(propskills);
        let skillMap = {}
        skills.sort()
        skills.forEach((skill)=>{
            skillMap[skill] = true
        })
        console.log(skillMap)
        return skillMap
    }

    ratingHandler = (rating)=>{
        if(this.state.applicants[0].rating!=rating){
            // alert("Guess kijiye hum kaha hai"+ rating)
            let index = 0
            let fetchedApplicant = {...this.state.applicants[index]}
            fetchedApplicant.rating = rating
            let applicantsCopy = [...this.state.applicants]
            applicantsCopy[index] = fetchedApplicant
            this.setState({applicants: applicantsCopy})
        }
    }

    getCollegeList = async () =>{
    //     let colleges = []
    //     let collegesDocs = await db.collection('suggestion').get();
    //     collegesDocs.forEach(collegeDoc=>{
    //         let college = collegeDoc.data();
    //         // college.id = collegeDoc.id;
    //         colleges.push(college);
    //         console.log(college);
    // });
    
    // let collegeNames = colleges[0].name
    // console.log(collegeNames, "colleges")
    // collegeNames.unshift("All")
    // let options = {...this.state.options}
    // options.collegeOptions = collegeNames
    // console.log(options, "Options")
    // this.setState({options: options})
    }

    // getImages = async (applicants) =>{
    //     applicants.forEach(async applicant=>{
    //         let src = ""
    //         // let profilepicLink = "users/"+ applicant.uid + '/myphoto.png'
    //         try{
    //             // src = await storage.ref().child(profilepicLink).getDownloadURL()
    //             // applicant.profilePic = src
    //             // console.log(applicant.profilePic)
    //             let index = this.state.applicants.findIndex((app)=>app.uid==applicant.uid);
    //             let fetchedApplicant = {...this.state.applicants[index]}
    //             fetchedApplicant.profilePic = src
    //             let applicantsCopy = [...this.state.applicants]
    //             applicantsCopy[index] = fetchedApplicant
    //             this.setState({applicants: applicantsCopy})
    //         }
    //         catch(error){
    //             console.log(error)
    //         }
    //     })
    // }

    getDegrees = async (college)=>{

        console.log("getting data for ", college)
        let collegeDoc = (await db.collection('clginfo').where('collegeid','==', college).get()).docs[0];
        return collegeDoc? collegeDoc.data() : {edu:{}}
    }

    applyFilterHandler = (filters, options)=>{
        console.log("filter Input Taker Called");
        if(this.state.filters!=filters){
            this.setState({filters:filters, options:options})
            console.log(filters, "filters")
            this.fetchStudents(filters);
        }
        else{
            console.log("same filters")
        }
    }
    
    updatestatus = (status)=>{
        if(status!=this.state.applicants[0].verified){
            let resumes = [...this.state.applicants]
            let resume = resumes[0]
            resumes = []
            resume.verified = status
            resumes.push(resume)
            this.setState({applicants:resumes})
        }
    }

    render(){

        let contextData = {state: this.state, updatef: this.updateflag, ratingHandler: this.ratingHandler, updatestat: this.updatestatus, filterfunction: this.applyFilterHandler, fetchStudents: this.fetchStudents, setSearch: this.setSearch, getDegrees: this.getDegrees}
    return (
        <StudentsContext.Provider value={contextData}>
            {this.props.children}
            {/* {console.log('Context render')} */}
        </StudentsContext.Provider>)
    }
    
}

const mapStateToProps = (state)=>({
    profile:state.auth.profile,
    user:state.auth.user,
})

const mapDispatchToProps = (dispatch)=>({
    createToast: (toast)=>dispatch(CreateToast(toast))
})

export const StudentsProvider = withRouter(connect(mapStateToProps, mapDispatchToProps)(StudentsProviderComponent));