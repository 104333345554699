import React from 'react';
import { NavLink } from 'react-router-dom';
import styles from './Nav.module.css';

const Nav = (props)=>{

    return (<nav className={styles.Nav}>
        <NavLink to="/" exact activeClassName={styles.ActiveLink}>Home</NavLink>
        <NavLink to="/students/" activeClassName={styles.ActiveLink}>Students</NavLink>
        <NavLink to="/rating/" activeClassName={styles.ActiveLink}>Rating</NavLink>
        <NavLink to="/companies/" activeClassName={styles.ActiveLink}>Companies</NavLink>
        <NavLink to="/colleges/" activeClassName={styles.ActiveLink}>Colleges</NavLink>
        <NavLink to="/jobs/" activeClassName={styles.ActiveLink}>Jobs</NavLink>
        <NavLink to="/bugs/" activeClassName={styles.ActiveLink}>Bug Reports</NavLink>
        <NavLink to="/demo/" activeClassName={styles.ActiveLink}>Demo Request</NavLink>
    </nav>)
}

export default Nav;