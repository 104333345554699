import React from 'react';
import styles from './JobsContainer.module.css';
import Input from '../shared/ui/Input/Input';
import SearchInput from '../shared/ui/Input/SearchInput';
import JobsTable from './JobsTable';


const JobContainer = (props)=> (
    <div className={styles.Content} >
        <h1>Jobs</h1>
        <div>
            <SearchInput/>
        </div>
        <JobsTable/>
    </div>
)

export default JobContainer;