import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Button from '../shared/ui/Button/Button';
import styles from './ChangeDeadline.module.css';
import Error from '../Rating/Error';
import * as Yup from 'yup';
import moment from 'moment';
import {db} from '../../firebase';
import {connect} from 'react-redux';
import {CreateToast} from '../../store/actions/alert';


const validationSchema = Yup.object({
    deadline: Yup.date().required("Required")
})

function ChangeDeadlineForm(props) {

    const [loading, setLoading] = useState(false);
    const initValues = {
        deadline : moment(props.job.deadline.toDate()).format("YYYY-MM-DD")
    }
    const onSubmit =(values)=> {
        let deadline = values['deadline'];
        deadline = moment(deadline, 'YYYY-MM-DD').endOf('day');
        let active = deadline.toDate() > new Date();
        
        setLoading(true)
        db.collection('jobs').doc(props.job.uid).update({status: active, deadline: deadline.toDate()})
        .then(()=>{
            setLoading(false);
            props.deadlineChanged(deadline, active);
            props.closeHandler();
            props.createToast({message: "Deadline changed"})
        } ).catch(e=> console.log(e));
    }
    return (
        <Formik validationSchema={validationSchema} initialValues ={initValues} onSubmit={onSubmit}>
            {(form)=>{
                return <Form className={styles.Form}>

                    <div className="FormField">
                        <label className={`InputLabel ${styles.DeadlineLabel}`}>
                            <span>Deadline</span>
                            <span className={styles.FromNow}>{moment(form.values['deadline'], 'YYYY-MM-DD').endOf('day').fromNow()}</span>
                            <Error name="deadline"/> 
                        </label>
                        <Field name="deadline" id="deadline" type="date" className="Input"/>
                    </div>
                    <Button loading={loading} primary type='submit' className={styles.Save}>Save</Button>
                </Form>
            }}
            
        </Formik>
    )
}

const mapDispatchToProps = (dispatch)=>({
    createToast : (toast)=> dispatch(CreateToast(toast))
})

export default connect(null, mapDispatchToProps)(ChangeDeadlineForm)
