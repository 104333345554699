import React, { useEffect, useState } from 'react';
import styles from './CompaniesTable.module.css';
import KebabMenu, {KebabOption} from '../shared/ui/KebabMenu/KebabMenu';
import Modal from '../shared/ui/Modal/Modal';
import {auth, db, apiURL} from '../../firebase';
import CompanyModalContent from './CompanyModalContent';
import axios from 'axios';

export default (props)=>{
    let [companies, setcompanies] = useState([]);
    let [showModal, setShowModal] = useState(false);
    let [selectedcompanyIndex, setSelectedcompanyIndex] = useState(-1);
    let [loadmoreLoading, setLoadmoreLoading] = useState(false);
    let [showLoadmore, setShowLoadmore] = useState(true);
    let limit = 10;

    useEffect(()=>{
        db.collection('company').orderBy('createdAt', 'desc').limit(limit).get().then(companiesData=>{
            let newcompanies = companiesData.docs.map((company)=> ({ uid: company.id, ...company.data() }));
            setcompanies(newcompanies);
            if(newcompanies.length < limit)
            setShowLoadmore(false)
        })
    return ()=>{
        // unsubscribe();
    }
    },[]);

    const loadMore = async ()=>{
        setLoadmoreLoading(true);
        let lastcompany = companies[companies.length - 1];
        let lastcompanyDoc = await db.collection('company').doc(lastcompany.uid).get();

        db.collection('company').orderBy('createdAt', 'desc').startAfter(lastcompanyDoc).limit(limit).get().then(companiesData=>{
            let newcompanies = companiesData.docs.map((company)=> ({ uid: company.id, ...company.data() }));
            setcompanies([...companies, ...newcompanies]);
            setLoadmoreLoading(false);
            if(newcompanies.length < limit)
                setShowLoadmore(false)
        }).catch(()=>setLoadmoreLoading(false));
    }

    const opencompany = (i)=>{
        setShowModal(true);
        setSelectedcompanyIndex(i);
    }

    const closecompany = ()=>{
        setShowModal(false)
    }


    const changeVerification = async (companyIndex, verification)=>{
        

        let company = {...companies[companyIndex]};
        company.verified = verification;
        try{
            await db.collection('company').doc(company.uid).update({verified: verification});
    
            let newcompanies = [...companies];
            newcompanies[companyIndex] = company;
            setcompanies(newcompanies);
        }
        catch(e){
            console.log(e);
        }
        return;
    }


    return(
    <>
        <Modal closeHandler={closecompany} show={ showModal && selectedcompanyIndex >-1 && (companies.length>0)}>
            <CompanyModalContent company={companies[selectedcompanyIndex]} changeVerification={(verification)=> changeVerification(selectedcompanyIndex, verification)}/>
        </Modal>
        <div className={styles.Table}>
            <div className={[styles.TableHeaderRow, styles.Row].join(" ")}>
                <span>Name</span>
                <span>Created At</span>
                <span className={[styles.CenterCell, styles.Cell].join(" ")}>Status</span>
                <span className={[styles.CenterCell, styles.Cell].join(" ")}>Actions</span>
            </div>
            <div className={styles.TableContent}>
                {companies.map((company, i)=> <CompanyRow changeVerification={(verification)=> changeVerification(i, verification)} opencompany={()=> opencompany(i)} key={i} company={company}/>)}
            </div>
            { showLoadmore && <div className={styles.Loadmore} onClick={loadMore}>
                Load More
            </div>}
        </div>

    </>)
}

const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }


const CompanyRow = ({company, ...props})=>{
    const loginAs = async ()=>{
        
        let token = await auth.currentUser.getIdToken()
        let url =apiURL + '/user/generateLoginToken';

        try {
            let customToken = (await axios.post( url, {email: company.email},{
                headers: {
                    token: token,
                }})).data.token;
            
                console.log(customToken);
                let loginUrl = "https://company.ensvee.com/login?token=" + customToken;
                openInNewTab(loginUrl)

        }
        catch{};

        
    }

    let status = company.verified ? "Verified" : "Unverified";
    let live  = "Open";
    let createdAt =  company.createdAt ? company.createdAt.toDate(): null;

    if(company.campus){
        let liveCount= 0, notLiveCount = 0;
        for(let college in company.recipient)
            company.recipient[college] =='live' ? liveCount++: notLiveCount++;

        if(liveCount && notLiveCount)
            live = "Partial"
        else if(liveCount)
            live = 'Live';
        else
            live = 'Pending'
    }

    let verifyButtonText = company.verified ? "Unverify" : "Verify";
    

    return(<div className={[styles.companyRow , styles.Row].join(" ")}>
        <div className={[styles.NameCell, styles.Cell].join(" ")} onClick={props.opencompany}>
            <span className={styles.Title}>
                {company.name}
            </span>
            <span className={styles.Subtitle}>
                {company.useremail}
            </span>
        </div>
        <div className={[styles.DeadlineCell, styles.Cell].join(" ")}>
            <span className={styles.Title}> { !createdAt ? "Unknown" : `${ Math.round(-1* (createdAt - new Date())/1000/60/60/24)} days ago` } </span>
            <span className={styles.Subtitle}>{ createdAt && createdAt.toDateString()}</span>
        </div>
        <div className={[styles.StatusCell, styles.Cell, styles.CenterCell].join(" ")}>
            <span className={[styles.Tag, styles[status]].join(" ")}>{status}</span>
        </div>
        <div className={[styles.Cell, styles.CenterCell, styles.ActionCell].join(" ")}>
            <KebabMenu>
                <KebabOption clicked={props.opencompany}>View</KebabOption>
                <KebabOption clicked={loginAs}>Login as</KebabOption>
                <KebabOption clicked={()=> props.changeVerification(!company.verified)}>{verifyButtonText}</KebabOption>
            </KebabMenu>
        </div>
    </div>)
}