export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS"
export const USER_LOGIN_FAILED = "USER_LOGIN_FAILED"

export const FIREBASE_AUTH_STATECHANGED = "FIREBASE_AUTH_STATECHANGED";
export const GET_PROFILE = "GET PROFILE"
export const SET_PROFILE = "SET PROFILE"

export const USER_LOGOUT = "USER_LOGOUT";
export const USER_LOGOUT_SUCCESS = "USER_LOGOUT_SUCCESS"
export const USER_LOGOUT_FAILED = "USER_LOGOUT_FAILED"

export const FETCH_ALL_JOBS ="FETCH_JOBS";
export const FETCH_ALL_JOB_REFS_SUCCESS = "FETCH_ALL_JOB_REFS_SUCCESS";

export const FETCH_JOB ="FETCH_JOB";
export const FETCH_JOB_FAILED ="FETCH_JOB_FAILED";
export const FETCH_JOB_SUCCESS ="FETCH_JOB_SUCCESS";

export const FETCH_ALL_JOBS_FAILED ="FETCH_ALL_JOBS_FAILED";
export const FETCH_ALL_JOBS_SUCCESS ="FETCH_ALL_JOBS_SUCCESS";


export const FETCH_JOB_DETAILS ="FETCH_JOB_DETAILS";
export const FETCH_JOB_DETAILS_FAILED ="FETCH_JOB_DETAILS_FAILED";
export const FETCH_JOB_DETAILS_SUCCESS ="FETCH_JOB_DETAILS_SUCCESS";


export const FETCH_APPLIED_STUDENTS = "FETCH_APPLIED_STUDENTS"
export const FETCH_APPLIED_STUDENTS_SUCCESS = "FETCH_APPLIED_STUDENTS_SUCCESS"
export const FETCH_APPLIED_STUDENTS_FAILED = "FETCH_APPLIED_STUDENTS_FAILED"

export const FETCH_STUDENT_START = "FETCH_STUDENT_START"
export const FETCH_STUDENT_SUCCESS = "FETCH_STUDENT_SUCCESS"
export const FETCH_STUDENT_FAILED = "FETCH_STUDENT_FAILED"

export const APPLY_FILTERS = "APPLY_FILTERS";
export const SEARCH = "SEARCH";

export const CREATE_ALERT = "CREATE ALERT";
export const REMOVE_ALERT = "REMOVE ALERT";
export const CREATE_TOAST = "CREATE TOAST";
export const REMOVE_TOAST = "REMOVE TOAST";