import React, {useRef, useEffect} from 'react';
import {Field, connect} from 'formik';
import styles from './UploadInput.module.css';
import Error from './Error';



function UploadInput({name, ...props}) {
    let formik = props.formik;

    let classes = [styles.UploadInput];
    if(props.className) classes.push(props.className);
    
    let dropRef = useRef();
    let dropArea = dropRef.current

    const handleDrag = (e) => {
        e.preventDefault()
        e.stopPropagation()
        dropArea.classList.add(styles.Highlight);
    }
    const handleDragIn = (e) => {
        e.preventDefault()
        e.stopPropagation()
        dropArea.classList.add(styles.Highlight)
    }
    const handleDragOut = (e) => {
        e.preventDefault()
        e.stopPropagation()
        dropArea.classList.remove(styles.Highlight)
    }

    const handleDrop = (e) => {
        e.preventDefault()
        e.stopPropagation()
        dropArea.classList.remove(styles.Highlight)
        formik.setFieldTouched(name, true)
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            formik.setFieldValue(name,e.dataTransfer.files[0], true)
            //e.dataTransfer.files
            e.dataTransfer.clearData()
        }
    }
    
    const fileSelectHandler = (e)=>{
        console.log(e);
        formik.setFieldTouched(name, true)
        formik.setFieldValue(name,e.target.files[0], true)
    }

    useEffect(()=>{
        if (!dropArea) return;
        dropArea.addEventListener('dragenter', handleDragIn)
        dropArea.addEventListener('dragleave', handleDragOut)
        dropArea.addEventListener('dragover', handleDrag)
        dropArea.addEventListener('drop', handleDrop)

        return ()=>{
            dropArea.removeEventListener('dragenter', handleDragIn)
            dropArea.removeEventListener('dragleave', handleDragOut)
            dropArea.removeEventListener('dragover', handleDrag)
            dropArea.removeEventListener('drop', handleDrop)
        }
    })


    return (
        <Field name={name}>
            {()=>{               

                return <div ref={dropRef} className={classes.join(" ")}>
                    <Error name={name}/>
                    <p className={styles.InputPlaceholder}>Drop a file here or <label className={styles.Browse} htmlFor="file">browse</label></p>
                    <p className={styles.FileName}>{formik.values[name]?.name}</p>
                    <input onChange={fileSelectHandler} id="file" className={styles.FileInput} accept={props.accept} type="file"/>
                </div>
            }}
        </Field>
    )
}




export default  connect(UploadInput);
