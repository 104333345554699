import React, { useEffect, useState } from "react";
import styles from "./JobsTable.module.css";
import KebabMenu, { KebabOption } from "../shared/ui/KebabMenu/KebabMenu";
import Modal, { ModalWithHeader2 } from "../shared/ui/Modal/Modal";
import { db } from "../../firebase";
import HomeInfo from "./HomeInfo";
import ChangeDeadlineForm from "./ChangeDeadlineForm";
import moment from "moment";

export default (props) => {
	let [jobs, setJobs] = useState([]);
	let [showModal, setShowModal] = useState(false);
	let [showChangeDeadline, setShowChangeDeadline] = useState(false);
	let [selectedJobIndex, setSelectedJobIndex] = useState(-1);
	let [loadmoreLoading, setLoadmoreLoading] = useState(false);
	let [showLoadmore, setShowLoadmore] = useState(true);
	let limit = 10;

	useEffect(() => {
		db.collection("jobs")
			.limit(limit)
			.get()
			.then((jobsData) => {
				let newJobs = jobsData.docs.map((job) => ({
					uid: job.id,
					...job.data(),
				}));
				setJobs(newJobs);
				if (newJobs.length < limit) setShowLoadmore(false);
			});
		return () => {
			// unsubscribe();
		};
	}, []);

	const loadMore = async () => {
		setLoadmoreLoading(true);
		let lastJob = jobs[jobs.length - 1];
		let lastJobDoc = await db.collection("jobs").doc(lastJob.uid).get();

		db.collection("jobs")
			.startAfter(lastJobDoc)
			.limit(limit)
			.get()
			.then((jobsData) => {
				let newJobs = jobsData.docs.map((job) => ({
					uid: job.id,
					...job.data(),
				}));
				setJobs([...jobs, ...newJobs]);
				setLoadmoreLoading(false);
				if (newJobs.length < limit) setShowLoadmore(false);
			})
			.catch(() => setLoadmoreLoading(false));
	};

	const openJob = (i) => {
		setShowModal(true);

		setSelectedJobIndex(i);
	};

	const openChangeDeadline = (i) => {
		setShowChangeDeadline(true);
		if (i !== undefined) setSelectedJobIndex(i);
	};

	const closeJob = () => {
		setShowModal(false);
	};

	const liveAllRecipients = async (jobIndex) => {
		let job = { ...jobs[jobIndex] };
		let recipient = { ...job.recipient };
		for (let college in recipient) recipient[college] = "live";

		try {
			await db.collection("jobs").doc(job.uid).update({ recipient });
			let newJobs = [...jobs];
			job.recipient = recipient;
			newJobs[jobIndex] = job;
			setJobs(newJobs);
		} catch {}
	};

	const changeStatus = (jobIndex, key, status) => {
		let job = { ...jobs[jobIndex] };
		job.recipient = { ...job.recipient };
		job.recipient[key] = status;

		let newJobs = [...jobs];
		newJobs[jobIndex] = job;
		setJobs(newJobs);
	};

	const deadlineChanged = (deadline, active) => {
		let job = { ...jobs[selectedJobIndex] };
		job.deadline = deadline;
		job.status = active;

		let newJobs = [...jobs];
		newJobs[selectedJobIndex] = job;
		setJobs(newJobs);
	};

	return (
		<>
			<Modal
				closeHandler={closeJob}
				show={showModal && selectedJobIndex > -1 && jobs.length > 0}
			>
				<HomeInfo
					job={jobs[selectedJobIndex]}
					changeStatus={(key, status) =>
						changeStatus(selectedJobIndex, key, status)
					}
					openChangeDeadline={() => openChangeDeadline()}
				/>
			</Modal>
			<ModalWithHeader2
				title="Change Deadline"
				show={
					showChangeDeadline &&
					selectedJobIndex > -1 &&
					jobs.length > 0
				}
				closeHandler={() => setShowChangeDeadline(false)}
				style={{ maxWidth: 500 }}
			>
				<ChangeDeadlineForm
					deadlineChanged={deadlineChanged}
					closeHandler={() => setShowChangeDeadline(false)}
					job={jobs[selectedJobIndex]}
				/>
			</ModalWithHeader2>
			<div className={styles.Table}>
				<div className={[styles.TableHeaderRow, styles.Row].join(" ")}>
					<span>Name</span>
					<span>Deadline</span>
					<span
						className={[styles.CenterCell, styles.Cell].join(" ")}
					>
						Status
					</span>
					<span
						className={[styles.CenterCell, styles.Cell].join(" ")}
					>
						Live Status
					</span>
					<span
						className={[styles.CenterCell, styles.Cell].join(" ")}
					>
						Actions
					</span>
				</div>
				<div className={styles.TableContent}>
					{jobs.map((job, i) => (
						<JobRow
							openChangeDeadline={() => openChangeDeadline(i)}
							liveAll={() => liveAllRecipients(i)}
							openJob={() => openJob(i)}
							key={i}
							job={job}
						/>
					))}
				</div>
				{showLoadmore && (
					<div className={styles.Loadmore} onClick={loadMore}>
						Load More
					</div>
				)}
			</div>
		</>
	);
};

const JobRow = ({ job, ...props }) => {
	let status = job.status ? "Active" : "Inactive";
	let live = "Open";
	let deadline = job.deadline.toDate();
	let deadlinePassed = new Date() - deadline > 0;

	if (job.campus) {
		let liveCount = 0,
			notLiveCount = 0;
		for (let college in job.recipient)
			job.recipient[college] == "live" ? liveCount++ : notLiveCount++;

		if (liveCount && notLiveCount) live = "Partial";
		else if (liveCount) live = "Live";
		else live = "Pending";
	}

	return (
		<div className={[styles.JobRow, styles.Row].join(" ")}>
			<div className={[styles.NameCell, styles.Cell].join(" ")}>
				<span className={styles.Title}>
					<span onClick={props.openJob}>{job.title}</span>{" "}
					<span className={styles.UID}>{job.uid}</span>
				</span>

				<span className={styles.Subtitle}>{job.company}</span>
			</div>
			<div className={[styles.DeadlineCell, styles.Cell].join(" ")}>
				<span className={styles.Title}>
					{" "}
					{deadlinePassed
						? `Passed`
						: moment(deadline).fromNow()}{" "}
				</span>
				<span className={styles.Subtitle}>
					{deadline.toDateString()}
				</span>
			</div>
			<div
				className={[
					styles.StatusCell,
					styles.Cell,
					styles.CenterCell,
				].join(" ")}
			>
				<span className={[styles.Tag, styles[status]].join(" ")}>
					{status}
				</span>
			</div>
			<div
				className={[
					styles.StatusCell,
					styles.Cell,
					styles.CenterCell,
				].join(" ")}
			>
				<span className={[styles.Tag, styles[live]].join(" ")}>
					{live}
				</span>
			</div>
			<div
				className={[
					styles.Cell,
					styles.CenterCell,
					styles.ActionCell,
				].join(" ")}
			>
				<KebabMenu>
					<KebabOption clicked={props.openJob}>View</KebabOption>
					<KebabOption clicked={props.openChangeDeadline}>
						Change Deadline
					</KebabOption>
					{job.campus && (
						<KebabOption clicked={props.liveAll}>
							Live All Colleges
						</KebabOption>
					)}
				</KebabMenu>
			</div>
		</div>
	);
};
