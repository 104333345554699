import React, {useState} from 'react';
import styles from '../Rating/RatingForm.module.css';
import { Formik,Form, Field, ErrorMessage, FieldArray } from 'formik';
import * as Yup from 'yup';
import SkillInput from './SkillInput';
import UploadInput from './UploadInput';
import Error from './Error';
import Button from '../shared/ui/Button/Button';
import { db, auth, storage } from '../../firebase';
import { v4 as uuidv4 } from 'uuid';

const initialValues = {
    title: "",
    skills: [],
}

const validationSchema = Yup.object({
    title : Yup.string().required("Required"),
    skills : Yup.array().min(1,'Atleast 1 required'),
    file : Yup.mixed().required("Required").test('xls', 'Upload an excel sheet', (val)=>{
        if(!val) return false;
        let arr =val.name.split('.')
        return arr[arr.length-1].includes('xls') || arr[arr.length-1].includes('xlsx')   
    })
})

const RatingForm = (props)=>{
    const [loading, setLoading] = useState(false);
    const onSubmit= async (values, {resetForm})=>{
        if(loading) return;
        setLoading(true);

        let path = `rating_files/${uuidv4()}${values.file.name}`;
        await storage.ref(path).put(values.file)
        let doc = {
            skill: values.skills,
            file_path: path,
            file_name: values.file.name,
            title: values.title,
            uploaded_by_email : auth.currentUser.email,
            status: "Just Uploaded",
            uploaded_at: new Date(),
        };


        await db.collection('rating_data').doc().set(doc);
        // setTimeout(()=>setLoading(false), 1000)
        resetForm();
        setLoading(false)
    }

    return (<Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {(props)=>{
            // console.log(props)
            return (
            <Form className={styles.Form}>
            <div className={styles.FormSection1}>
                <div className="FormField">
                    <label className="InputLabel" htmlFor="title"><span>Title</span> <Error name="title" /></label>
                    <Field className="Input" placeholder="Anything that describes it" name="title" id="title" />
                    
                </div>
                <div className="FormField">
                    <label className="InputLabel" htmlFor="skill"><span>Skills</span> <Error name="skills" /></label>
                    <SkillInput name="skills" placeholder="Java, English..."/>
                </div>
            </div>
            <UploadInput name="file" accept=".xls,.xlsx"/>
            <Button primary loading={loading} className={styles.Button} type="submit">Upload</Button>
            </Form>)
            }
        }
    </Formik>
    )
}

export default RatingForm;