import logo from './logo.svg';
import './App.css';
import {  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import firebase from './firebase';
import {connect} from 'react-redux';
import {AuthStateChanged} from './store/actions/auth';
import LoginContainer  from "./components/Login/Login";
import AppliedStudentsContainer from './components/AppliedStudents/AppliedStudents';
import ProtectedRoute from './components/shared/ProtectedRoute/ProtectedRoute';
import AppLoading from './components/shared/AppLoader/AppLoader';
import Alerts from './components/Alerts/Alerts';
import Toasts from './components/Alerts/Toasts'
import Layout from './components/shared/Layout/Layout';

import StudentContainer from './components/Students/StudentsContainer';
import JobContainer from './components/Jobs/JobsContainer';
import CompaniesContainer from './components/Companies/CompaniesContainer';
import RatingContainer from './components/Rating/RatingContainer';

function App(props) {

  firebase.auth().onAuthStateChanged(user=>
    {
      props.authStateChange(user);
      
      console.log('Auth State Changed', user);
    });
    
  console.log("Rendering App")
  return (
    <AppLoading>
      <Router>
        <Switch>
          <Route path="/login" component={LoginContainer}/>
          <Route path="/">
            <Layout>
              <Switch>
                <ProtectedRoute path="/students" component={StudentContainer}  ignoreVerification/>
                <ProtectedRoute path="/rating" component={RatingContainer}  ignoreVerification/>
                <ProtectedRoute path="/jobs" component={JobContainer} ignoreVerification/>
                <ProtectedRoute path="/companies/" component={CompaniesContainer} ignoreVerification/>
              </Switch>
            </Layout>
          </Route>
          {/* <ProtectedRoute path="/createaccount" component={CreateAccount} ignoreVerification  /> */}
          {/* <ProtectedRoute path="/jobs/:jobId/hired" render= {(props)=><AppliedStudentsContainer hired {...props}/>}/> */}
          {/* <ProtectedRoute path="/jobs/:jobId" component= {AppliedStudentsContainer}/> */}
          <ProtectedRoute ignoreVerification path="/" component={AppliedStudentsContainer}/>
        </Switch>
      </Router>
      <Alerts/>
      <Toasts/>
    </AppLoading>
    
  );
}

const mapDispatchToProps = (dispatch)=>({
  authStateChange: (user)=> dispatch(AuthStateChanged(user))
})
export default connect( null , mapDispatchToProps)(App);
