import React, { Component, createRef} from 'react';
import styles from './SearchBar.module.css';
import Button from '../shared/ui/Button/Button';
import filterIcon from '../../assets/icons/filter.svg';
import {StudentsContext} from './StudentsContext';
import { Search } from '../../store/actions/jobs';
import SearchByTag from './SearchByTag'

class SearchBar extends Component{
    inputRef = createRef(null);
    searchInputHandler=(e)=>{
        if(e.type=="click") e.preventDefault();
        setTimeout(()=>{
            let search = e.target.value || this.inputRef.current.value;
            console.log(search,e, 'search')
            this.context.setSearch(search);

        }, 100)
    }

    render(){
        return(<div>
            <form className={styles.SearchRow} style={{width:'100%'}}>
                <input ref={this.inputRef} onPaste={e=> this.searchInputHandler(e)} className={styles.SearchInput} type="Text" placeholder="Search by Email"/>
                {!this.context.state.showHired && <button onClick={(e)=>this.searchInputHandler(e)} width="unset" style={{display:'none', marginLeft:20, padding:21, borderColor:'#D3D5DB'}}>
                </button>}
            </form>
        </div>)
    }
}

SearchBar.contextType = StudentsContext;

export default SearchBar;