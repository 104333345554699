import React from 'react';
import Nav from './Nav';
import styles from './Layout.module.css';
import logo from '../../../assets/images/ensvee-logo.svg';
import Button from '../ui/Button/Button';
import {auth} from '../../../firebase';
import logout from '../../../assets/icons/logout.svg'
const Layout = (props)=>(
    <div className={styles.Container}>
        <aside className={styles.SideBar}>
            <div>
                <h1>Coffee Ensvee</h1>
                <p className={styles.Email}>{auth.currentUser?auth.currentUser.email: "Anonymous"}</p>
            </div>
            <Nav/>
            <Button className={styles.Logout} clicked={()=> auth.signOut()}>
                <span>Logout</span>
                <img className={styles.LogoutIcon} src={logout}/>
            </Button>
        </aside>
        <main className={styles.Main}>
            <header className={styles.Header}>
                <img src={logo}/>
            </header>
            {props.children}  
        </main>

    </div>
)

export default Layout;