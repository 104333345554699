import React, {useRef} from 'react';
import {FieldArray} from 'formik';
import styles from './SkillInput.module.css';
import cross from '../../assets/icons/cross.svg';
const SkillInput = ({name, ...props})=>{

    const skillInputRef = useRef();
    return (<FieldArray name={name}>
        {({push,remove, form})=>{
            
            const skills = form.values.skills;

            const onEnter = e=>{
            
                const newVal = skillInputRef.current.value.toLowerCase();
                if (e.keyCode !== 13) return;
                e.preventDefault();
                if(skills.includes(newVal)) return false;
                skillInputRef.current.value = ""
                push(newVal);
                return false;
            }

            const removeSkill = (i) =>{
                remove(i);
                form.setFieldTouched(name);
            }
            // console.log(form.values);
            return (
                    <div>
                        <input name={name} ref={skillInputRef} className="Input" onKeyDown={onEnter} {...props} onBlur={form.handleBlur} />
                        {Boolean(form.values[name]?.length) && <div className={styles.SkillContainer}>
                            {form.values[name].map((skill,i)=>(
                                <span onClick={()=>removeSkill(i)} className={styles.Skill} key={i}><span>{skill}</span> <img className={styles.Cross} src={cross}/></span>
                            ))}
                        </div>}
                    </div>
                    )
                }
        }
    </FieldArray>
    )
}

export default SkillInput;